import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import useSiteMetadata from '../components/SiteMetadata';

//Components
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import ColoringPostTemplate from '../components/ColoringPostTemplate/ColoringPostTemplate';
import ColoringList from '../components/ColoringList';

const ColoringPost = ({ data }) => {
	const { markdownRemark: post } = data;
	const { pathname } = useLocation();

	const { siteURL } = useSiteMetadata();

	return (
		<Layout background={post.frontmatter.background}>
			<ColoringPostTemplate
				content={post.html}
				contentComponent={HTMLContent}
				description={post.frontmatter.description}
				instagram={post.frontmatter.instagram}
				thumbnail={post.frontmatter.thumbnail}
				pdfFile={post.frontmatter.pdf.publicURL}
				helmet={
					<Helmet titleTemplate="%s - Coloring Popeyes">
						<title>{`${post.frontmatter.title}`}</title>
						<meta name="description" content={`${post.frontmatter.description}`} />

						<meta property="og:title" content={`${post.frontmatter.title} - Coloring Popeyes`} />
						<meta property="og:url" content={`${siteURL}${pathname}`} />
						<meta property="og:image" content={`${siteURL}${post.frontmatter.sharedimage.childImageSharp.fixed.src}`} />

						<meta name="twitter:title" content={`${post.frontmatter.title} - Coloring Popeyes`} />		
						<meta name="twitter:image" content={`${siteURL}${post.frontmatter.sharedimage.childImageSharp.fixed.src}`} />
					</Helmet>
				}
				title={post.frontmatter.title}
			/>

			<section className="section coloring-list-small">
				<div className="container">
					<h2 className="title is-2 has-text-centered">Other Coloring Pages</h2>

					<div className="columns is-centered is-paddingless">
						<div className="column is-8">
							<ColoringList random={true} itemsCount={4} exclude={post.id} columnWidth={3} responsive={false} />
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
};

ColoringPost.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default ColoringPost;

export const pageQuery = graphql`
	query ColoringPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			fields {
				slug
			}
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				background
				instagram
				pdf {
					publicURL
				}
				thumbnail {
					childImageSharp {
						fluid(maxWidth: 1024, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				sharedimage {
					childImageSharp {
						fixed(width: 1200, height: 630) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`;
