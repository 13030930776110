import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import InstagramEmbed from '@aarnila/react-instagram-embed';

//Components
import Content from '../Content';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import SocialShare from '../SocialShare/SocialShare';
import Modal from '../Modal/Modal';

//Styles
import './ColoringPostTemplate.scss';

//Images
import DownloadIcon from '../../img/download.svg';

const ColoringPostTemplate = ({
	content,
	contentComponent,
	title,
	description,
	instagram,
	thumbnail,
	pdfFile,
	helmet,
}) => {
	const PostContent = contentComponent || Content;

	const [isInstagramModalOpened, setIsInstagramModalOpened] = useState(false);

	const trackDownload = (e) => {
		trackCustomEvent({
			category: `Coloring - ${title}`,
			action: 'Download'
		});
	};

	return (
		<section className="section coloring-post">
			{helmet || ''}

			<div className="container content">
				<div className="columns is-centered is-vcentered is-mobile is-multiline">
					<div className="column is-4-desktop is-10-mobile has-text-centered">
						{thumbnail ? (
							<div className="card is-unselectable">
								<div className="card-image">
									<figure className="image is-marginless">
										<PreviewCompatibleImage
											imageInfo={{
												image: thumbnail,
												alt: title,
											}}
										/>
									</figure>
								</div>
							</div>
						) : null}

						<a className="button is-download is-mobile" href={pdfFile} download onClick={trackDownload}>
							<span className="icon is-small">
								<DownloadIcon />
							</span>
							<span>Download</span>
						</a>
					</div>

					<div className="column is-4-desktop is-10-mobile">
						<h1 className="title is-2">{title}</h1>
						<h2 className="subtitle is-3">{description}</h2>

						<PostContent content={content} />

						{instagram ? (
							<p>
								<button className="button is-text" rel="noopener noreferrer" onClick={(e) => { setIsInstagramModalOpened(true); } }>
									Watch the model
								</button>
							</p>
						) : null}

						{typeof window !== 'undefined' ? (
							<SocialShare shareUrl={window.location.href} title={title} />
						) : null}
					</div>
				</div>

				<div className="columns is-centered is-vcentered is-hidden-mobile">
					<div className="column is-4-desktop has-text-centered">
						<a className="button is-download" href={pdfFile} download onClick={trackDownload}>
							<span className="icon is-small">
								<DownloadIcon />
							</span>
							<span>Download</span>
						</a>
					</div>
					<div className="column is-4-desktop"></div>
				</div>
			</div>

			<Modal isOpened={isInstagramModalOpened} onClose={() => setIsInstagramModalOpened(false)}>
				<InstagramEmbed
					accessToken="1031255227378869|c76436dd77ec50a5fd3e7b72ec57695d"
					url={instagram}
					maxWidth={500}
					hideCaption={true}
					injectScript
				/>
			</Modal>
		</section>
	)
};

ColoringPostTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	instagram: PropTypes.string,
	helmet: PropTypes.object,
};

export default ColoringPostTemplate;