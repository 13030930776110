import React from 'react';
import { TransitionPortal } from "gatsby-plugin-transition-link";

//Styles
import './Modal.scss';

const Modal = ({children, isOpened, onClose}) => {

	return (
		<TransitionPortal>
			<div className={`modal ${isOpened ? 'is-active' : ''}`}>
				<div className="modal-background" onClick={() => onClose()} role="button"></div>
				<div className="modal-content">
					{children}
				</div>
				<button className="modal-close is-large" aria-label="close" onClick={() => onClose()}></button>
			</div>
		</TransitionPortal>
	);
};

export default Modal;