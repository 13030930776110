
import React, { useState } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

//Styles
import './SocialShare.scss';

//Images
import FacebookIcon from '../../img/social/facebook.svg';
import TwitterIcon from '../../img/social/twitter.svg';
import EmailIcon from '../../img/social/email.svg';
import LinkIcon from '../../img/social/link.svg';
import CheckIcon from '../../img/check.svg';

const SocialShare = ({ shareUrl, title }) => {

	const [isInfosDisplayed, setIsInfosDisplayed] = useState(false);

	const share = platform => {
		let shareLink;

		switch (platform) {
			case "Twitter":
				shareLink = `https://twitter.com/intent/tweet?text=${shareUrl}`;
				break;

			default:
				shareLink = `https://m.facebook.com/sharer.php?u=${shareUrl}`;
				break;
		}

		window.open(
			shareLink,
			"Facebook",
			"menubar=no, status=no, menubar=no, width=545, height=750"
		);

		trackCustomEvent({
			category: `Coloring - ${title}`,
			action: 'Share',
			label: platform
		});
	};

	const mailto = e => {
		window.open('mailto:?body=' + shareUrl + '&subject=Coloring%20Popeyes', '_self');

		trackCustomEvent({
			category: `Coloring - ${title}`,
			action: 'Share',
			label: 'Email'
		});
	};

	const copyLink = e => {
		navigator.clipboard.writeText(shareUrl);

		trackCustomEvent({
			category: `Coloring - ${title}`,
			action: 'Share',
			label: 'Copy link'
		});

		setIsInfosDisplayed(true);

		setTimeout(() => {
			setIsInfosDisplayed(false);
		}, 1500);
	};

	return (
		<div className="social-share field is-grouped">
			<button className="button is-rounded" onClick={() => { share('Facebook'); }}>
				<FacebookIcon />
			</button>

			<button className="button is-rounded" onClick={() => { share('Twitter'); }}>
				<TwitterIcon />
			</button>

			<button className="button is-rounded" onClick={mailto}>
				<EmailIcon />
			</button>

			<button className="button copy-link is-rounded" onClick={copyLink}>
				<LinkIcon />
			</button>

			<div className={`infos ${isInfosDisplayed ? 'is-active' : ''}`}>
				<CheckIcon />
				<span>Link copied!</span>
			</div>
		</div>
	)
};

export default SocialShare;